<template>
  <CRow class="d-flex">
    <CCol md="12">
      <CCard border-color="light" class="shadow-sm">
        <CCardBody> </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style scoped>
.underline {
  text-decoration: none;
}
.box:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
</style>
